//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-948:_9504,_2428,_7616,_3573,_1960,_133,_4248,_6868;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-948')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-948', "_9504,_2428,_7616,_3573,_1960,_133,_4248,_6868");
        }
      }catch (ex) {
        console.error(ex);
      }