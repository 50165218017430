//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-948:_9424,_8388,_7144,_608,_6848,_3806,_9044,_9264;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-948')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-948', "_9424,_8388,_7144,_608,_6848,_3806,_9044,_9264");
        }
      }catch (ex) {
        console.error(ex);
      }